<template>
	<template v-if="currentSchoolyear && adminSchoolyear">
		<el-card class="mb-10" shadow="hover" v-if="parent && currentParent">
			<el-page-header
				:title="$t('back')"
				:content="currentParent.name"
				@back="$router.back()" />
		</el-card>

		<el-card shadow="hover">
			<el-row align="middle">
				<el-col :span="5">
					<div class="text-left">
						<h3>{{ $t('studentsList') }}</h3>
					</div>
				</el-col>
				<el-col :span="19">
					<div class="text-right">
						<el-select
							filterable
							size="default"
							v-if="!parent"
							v-model="currentClass"
							:placeholder="$t('select')"
							:no-data-text="$t('noData')"
							:no-match-text="$t('noMatchingData')"
							style="width: 250px"
							class="mr-5">
							<el-option
								v-for="item in classes"
								:key="item.id"
								:value="item.id"
								:label="getClassName(item.id)">
							</el-option>
						</el-select>
						<el-button
							v-if="!parent"
							type="primary"
							size="default"
							:disabled="!multipleSelection.length || isLoading"
							icon="switch"
							class="mr-5"
							@click="transferDialog = true">
							{{ $t('transferToAnotherClass') }}
						</el-button>
						<el-button size="default" @click="addItem()">
							{{ $t('add') }}
						</el-button>
					</div>
				</el-col>
			</el-row>

			<el-divider></el-divider>

			<el-table
				:data="pagedTableData"
				:empty-text="$t('noData')"
				style="width: 100%"
				border
				stripe
				:default-sort="{ prop: 'lastname', order: 'ascending' }"
				@selection-change="handleSelectionChange"
				v-loading="isLoading">
				<el-table-column
					type="selection"
					width="50"
					v-if="!parent"></el-table-column>
				<el-table-column type="index" :index="indexMethod" width="50" fixed>
				</el-table-column>
				<el-table-column
					sortable
					:label="$t('photo')"
					prop="photo"
					width="100"
					fixed>
					<template #default="scope">
						<el-avatar
							:src="scope.row.photo"
							v-if="scope.row.photo"></el-avatar>
						<el-avatar icon="avatar" v-else></el-avatar>
					</template>
				</el-table-column>
				<el-table-column
					sortable
					:label="$t('lastname')"
					prop="lastname"
					width="150"
					fixed>
				</el-table-column>
				<el-table-column
					sortable
					:label="$t('firstnames')"
					prop="firstname"
					width="150"
					fixed>
				</el-table-column>
				<el-table-column sortable :label="$t('sex')" prop="sex" width="150">
					<template #default="scope">
						<el-tag :type="scope.row.sex == Sexes.MALE.id ? '' : 'danger'">
							{{ getSexLabel(scope.row.sex) }}
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column
					sortable
					:label="$t('parent')"
					prop="parent"
					width="150"
					v-if="!parent">
					<template #default="scope">
						{{ getParentName(scope.row.parent) }}
					</template>
				</el-table-column>
				<el-table-column sortable :label="$t('class')" prop="class" width="150">
					<template #default="scope">
						{{ getClassName(scope.row.class) }}
					</template>
				</el-table-column>
				<el-table-column
					sortable
					:label="$t('birthday')"
					prop="birthday"
					width="180">
					<template #default="scope">
						{{ scope.row.birthday ? reformatDate(scope.row.birthday) : '' }}
					</template>
				</el-table-column>
				<el-table-column
					sortable
					:label="$t('birthplace')"
					prop="birthplace"
					width="180">
				</el-table-column>
				<el-table-column
					sortable
					:label="$t('nationality')"
					prop="nationality"
					width="150">
				</el-table-column>
				<el-table-column
					sortable
					:label="$t('idNumber')"
					prop="idnum"
					width="150">
				</el-table-column>

				<el-table-column align="right" width="225" fixed="right">
					<template #header>
						<el-input
							v-model="search"
							size="small"
							:placeholder="$t('search')" />
					</template>

					<template #default="scope">
						<el-button size="small" @click="editItem(scope.$index, scope.row)">
							{{ $t('edit') }}
						</el-button>
						<el-button
							size="small"
							type="danger"
							@click="deleteItem(scope.$index, scope.row)">
							{{ $t('delete') }}
						</el-button>
					</template>
				</el-table-column>
			</el-table>

			<div style="text-align: center; margin-top: 20px">
				<el-pagination
					background
					layout="prev, pager, next"
					@current-change="setPage"
					:hide-on-single-page="true"
					:page-size="pageSize"
					:total="total">
				</el-pagination>
			</div>

			<div class="text-right mt-5" v-if="!parent && multipleSelection.length">
				<small v-if="multipleSelection.length > 1"
					><b>{{
						$t('xStudentsSelected', [multipleSelection.length])
					}}</b></small
				>
				<small v-else
					><b>{{ $t('oneStudentSelected') }}</b></small
				>
			</div>
		</el-card>

		<el-dialog
			:title="$t('addStudent')"
			v-model="addDialog"
			width="70%"
			top="8vh">
			<el-row :gutter="5" class="panel-group">
				<el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
					<el-card class="card-panel" shadow="never">
						<template #header>
							<div class="card-header">
								<b>{{ $t('studentPhoto') }}</b>
							</div>
						</template>
						<div class="text-center">
							<el-upload
								class="photo-uploader"
								accept="image/jpeg,image/jpg,image/gif,image/png"
								:data="photoData"
								:action="STORAGE_API_BASE_URL"
								:drag="true"
								:multiple="false"
								:show-file-list="false"
								:on-error="handlePhotoError"
								:on-success="handlePhotoSuccess"
								:before-upload="beforePhotoUpload">
								<el-avatar
									:size="125"
									:src="addStudent.photo"
									v-if="addStudent.photo"></el-avatar>
								<el-avatar :size="125" icon="avatar" v-else></el-avatar>
							</el-upload>

							<template v-if="addStudent.photo">
								<el-divider></el-divider>
								<el-button type="link" @click="clearPhoto()">{{
									$t('clear')
								}}</el-button>
							</template>
						</div>
					</el-card>
				</el-col>
				<el-col :xs="36" :sm="36" :lg="18" class="card-panel-col">
					<el-card class="card-panel" shadow="never">
						<div>
							<el-form
								:inline="true"
								:rules="rules"
								:model="addStudent"
								label-position="top"
								:ref="addStudentForm"
								hide-required-asterisk>
								<el-form-item prop="lastname" :label="$t('lastname')">
									<el-input
										:placeholder="$t('lastname')"
										v-model="addStudent.lastname"
										style="width: 200px" />
								</el-form-item>

								<el-form-item prop="firstname" :label="$t('firstnames')">
									<el-input
										:placeholder="$t('firstnames')"
										v-model="addStudent.firstname"
										style="width: 200px" />
								</el-form-item>

								<el-form-item prop="sex" :label="$t('sex')">
									<el-select
										v-model="addStudent.sex"
										:placeholder="$t('select')"
										:no-data-text="$t('noData')"
										:no-match-text="$t('noMatchingData')"
										style="width: 200px">
										<el-option
											v-for="item in sexes"
											:key="item.id"
											:value="item.id"
											:label="item.label">
										</el-option>
									</el-select>
								</el-form-item>

								<el-form-item prop="birthday" :label="$t('birthday')">
									<el-date-picker
										type="date"
										:placeholder="$t('birthday')"
										v-model="addStudent.birthday"
										style="width: 200px" />
								</el-form-item>

								<el-form-item prop="birthplace" :label="$t('birthplace')">
									<el-input
										:placeholder="$t('birthplace')"
										v-model="addStudent.birthplace"
										style="width: 200px" />
								</el-form-item>

								<el-form-item prop="nationality" :label="$t('nationality')">
									<el-input
										:placeholder="$t('nationality')"
										v-model="addStudent.nationality"
										style="width: 200px" />
								</el-form-item>

								<el-form-item prop="class" :label="$t('class')">
									<el-select
										filterable
										v-model="addStudent.class"
										:placeholder="$t('select')"
										:no-data-text="$t('noData')"
										:no-match-text="$t('noMatchingData')"
										style="width: 200px">
										<el-option
											v-for="item in classes"
											:key="item.id"
											:value="item.id"
											:label="getClassName(item.id)">
										</el-option>
									</el-select>
								</el-form-item>

								<el-form-item
									prop="parent"
									:label="$t('parent')"
									v-if="!parent">
									<el-select
										filterable
										v-model="addStudent.parent"
										:placeholder="$t('select')"
										:no-data-text="$t('noData')"
										:no-match-text="$t('noMatchingData')"
										style="width: 200px">
										<el-option
											v-for="item in parents"
											:key="item.id"
											:value="item.id"
											:label="item.name">
										</el-option>
									</el-select>
								</el-form-item>

								<el-form-item prop="idnum" :label="$t('idNumber')">
									<el-input
										:placeholder="$t('idNumber')"
										v-model="addStudent.idnum"
										style="width: 200px" />
								</el-form-item>
							</el-form>
						</div>
					</el-card>
				</el-col>
			</el-row>

			<template #footer>
				<span class="dialog-footer">
					<el-button @click="cancelForm(addStudentForm)">{{
						$t('cancel')
					}}</el-button>
					<el-button type="primary" @click="submitForm(addStudentForm)">{{
						$t('confirm')
					}}</el-button>
				</span>
			</template>
		</el-dialog>

		<el-dialog
			:title="$t('editStudent')"
			v-model="editDialog"
			width="70%"
			top="8vh">
			<el-row :gutter="5" class="panel-group">
				<el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
					<el-card class="card-panel" shadow="never">
						<template #header>
							<div class="card-header">
								<b>{{ $t('studentPhoto') }}</b>
							</div>
						</template>
						<div class="text-center">
							<el-upload
								class="photo-uploader"
								accept="image/jpeg,image/jpg,image/gif,image/png"
								:data="photoData"
								:action="STORAGE_API_BASE_URL"
								:drag="true"
								:multiple="false"
								:show-file-list="false"
								:on-error="handlePhotoError"
								:on-success="handlePhotoSuccess"
								:before-upload="beforePhotoUpload">
								<el-avatar
									:size="125"
									:src="editStudent.photo"
									v-if="editStudent.photo"></el-avatar>
								<el-avatar :size="125" icon="avatar" v-else></el-avatar>
							</el-upload>

							<template v-if="editStudent.photo">
								<el-divider></el-divider>
								<el-button type="link" @click="clearPhoto()">{{
									$t('clear')
								}}</el-button>
							</template>
						</div>
					</el-card>
				</el-col>
				<el-col :xs="36" :sm="36" :lg="18" class="card-panel-col">
					<el-card class="card-panel" shadow="never">
						<div>
							<el-form
								:inline="true"
								:rules="rules"
								:model="editStudent"
								label-position="top"
								:ref="editStudentForm"
								hide-required-asterisk>
								<el-form-item prop="lastname" :label="$t('lastname')">
									<el-input
										:placeholder="$t('lastname')"
										v-model="editStudent.lastname"
										style="width: 200px" />
								</el-form-item>

								<el-form-item prop="firstname" :label="$t('firstnames')">
									<el-input
										:placeholder="$t('firstnames')"
										v-model="editStudent.firstname"
										style="width: 200px" />
								</el-form-item>

								<el-form-item prop="sex" :label="$t('sex')">
									<el-select
										v-model="editStudent.sex"
										:placeholder="$t('select')"
										:no-data-text="$t('noData')"
										:no-match-text="$t('noMatchingData')"
										style="width: 200px">
										<el-option
											v-for="item in sexes"
											:key="item.id"
											:value="item.id"
											:label="item.label">
										</el-option>
									</el-select>
								</el-form-item>

								<el-form-item prop="birthday" :label="$t('birthday')">
									<el-date-picker
										type="date"
										:placeholder="$t('birthday')"
										v-model="editStudent.birthday"
										style="width: 200px" />
								</el-form-item>

								<el-form-item prop="birthplace" :label="$t('birthplace')">
									<el-input
										:placeholder="$t('birthplace')"
										v-model="editStudent.birthplace"
										style="width: 200px" />
								</el-form-item>

								<el-form-item prop="nationality" :label="$t('nationality')">
									<el-input
										:placeholder="$t('nationality')"
										v-model="editStudent.nationality"
										style="width: 200px" />
								</el-form-item>

								<el-form-item prop="class" :label="$t('class')">
									<el-select
										filterable
										v-model="editStudent.class"
										:placeholder="$t('select')"
										:no-data-text="$t('noData')"
										:no-match-text="$t('noMatchingData')"
										style="width: 200px">
										<el-option
											v-for="item in classes"
											:key="item.id"
											:value="item.id"
											:label="getClassName(item.id)">
										</el-option>
									</el-select>
								</el-form-item>

								<el-form-item
									prop="parent"
									:label="$t('parent')"
									v-if="!parent">
									<el-select
										filterable
										v-model="editStudent.parent"
										:placeholder="$t('select')"
										:no-data-text="$t('noData')"
										:no-match-text="$t('noMatchingData')"
										style="width: 200px">
										<el-option
											v-for="item in parents"
											:key="item.id"
											:value="item.id"
											:label="item.name">
										</el-option>
									</el-select>
								</el-form-item>

								<el-form-item prop="idnum" :label="$t('idNumber')">
									<el-input
										:placeholder="$t('idNumber')"
										v-model="editStudent.idnum"
										style="width: 200px" />
								</el-form-item>
							</el-form>
						</div>
					</el-card>
				</el-col>
			</el-row>

			<template #footer>
				<span class="dialog-footer">
					<el-button @click="cancelForm(editStudentForm)">{{
						$t('cancel')
					}}</el-button>
					<el-button type="primary" @click="submitForm(editStudentForm)">{{
						$t('confirm')
					}}</el-button>
				</span>
			</template>
		</el-dialog>

		<el-dialog width="35%" v-model="transferDialog" :title="$t('selectClass')">
			<div class="text-center">
				<el-select
					filterable
					size="default"
					v-model="transferClass"
					:placeholder="$t('select')"
					:no-data-text="$t('noData')"
					:no-match-text="$t('noMatchingData')"
					style="width: 250px"
					class="mr-10">
					<el-option
						v-for="item in classes"
						:key="item.id"
						:value="item.id"
						:label="getClassName(item.id)">
					</el-option>
				</el-select>
			</div>

			<template #footer>
				<span class="dialog-footer">
					<el-button @click="transferDialog = false">{{
						$t('cancel')
					}}</el-button>
					<el-button type="primary" @click="transfer()">{{
						$t('confirm')
					}}</el-button>
				</span>
			</template>
		</el-dialog>
	</template>

	<el-alert :title="$t('noCurrentSchoolyearWarning')" type="warning" v-else />
</template>

<script>
	import { mapState, mapGetters, mapActions } from 'vuex'

	import fr from 'element-plus/lib/locale/lang/fr'

	import { Roles, Sexes } from '@/utils/enums'
	import { formatDate, reformatDate } from '@/utils/chronos'
	import { genPassword, extractUppercaseWords } from '@/utils/functions'

	import { v4 as uuidv4 } from 'uuid'
	import sanitizer from 'string-sanitizer'

	import { STORAGE_API_BASE_URL } from '@/utils/constants'

	import accessManager from '@/mixins/access-manager'

	export default {
		name: 'Students',
		mixins: [accessManager],
		data() {
			return {
				Sexes,
				sexes: [],
				classes: [],
				parents: [],
				establishments: [],
				transferClass: null,
				currentClass: null,
				currentParent: null,
				multipleSelection: [],
				primaryColor: process.env.VUE_APP_PRIMARY_COLOR,
				editStudent: {
					lastname: '',
					firstname: '',
					sex: '',
					birthday: '',
					birthplace: '',
					nationality: '',
					idnum: '',
					photo: '',
					class: '',
					parent: '',
				},
				addStudent: {
					lastname: '',
					firstname: '',
					sex: '',
					birthday: '',
					birthplace: '',
					nationality: '',
					idnum: '',
					photo: '',
					class: '',
					parent: '',
				},
				rules: {
					lastname: [
						{
							required: true,
							message: this.$t('fieldRequired'),
							trigger: 'blur',
						},
					],
					firstname: [
						{
							required: true,
							message: this.$t('fieldRequired'),
							trigger: 'blur',
						},
					],
					class: [
						{
							required: true,
							message: this.$t('fieldRequired'),
							trigger: 'blur',
						},
					],
					sex: [
						{
							required: true,
							message: this.$t('fieldRequired'),
							trigger: 'blur',
						},
					],
					birthday: [
						{
							required: false,
						},
					],
					birthplace: [
						{
							required: false,
						},
					],
					nationality: [
						{
							required: false,
						},
					],
					idnum: [
						{
							required: false,
						},
					],
					photo: [
						{
							required: false,
						},
					],
					parent: [
						{
							required: false,
						},
					],
				},
				photoData: {
					folder: 'photos',
				},
				editStudentForm: 'editStudentForm',
				addStudentForm: 'addStudentForm',
				currentEditItem: null,
				transferDialog: false,
				editDialog: false,
				isLoading: false,
				addDialog: false,
				tableData: [],
				pageSize: 12,
				locale: fr,
				search: '',
				total: 0,
				page: 1,
				STORAGE_API_BASE_URL,
			}
		},
		created() {
			this.fetchStudents()

			var self = this

			this.$_.forIn(Sexes, function (value, key) {
				self.sexes.push(value)
			})
		},
		watch: {
			classes: {
				handler(val, oldVal) {
					if (!oldVal.length && val.length)
						this.currentClass = this.classes[0].id
				},
			},
		},
		computed: {
			...mapState({
				userData: (state) => state.session.userData,
			}),
			...mapGetters({
				getSettingValue: 'settings/getSettingValue',
			}),
			pagedTableData() {
				this.total = this.searching.length

				return this.searching.slice(
					this.pageSize * this.page - this.pageSize,
					this.pageSize * this.page
				)
			},
			searching() {
				if (!this.search) {
					this.total = this.filteredTableData.length
					return this.filteredTableData
				}

				this.page = 1

				return this.filteredTableData.filter(
					(data) =>
						data.lastname.toLowerCase().includes(this.search.toLowerCase()) ||
						data.firstname.toLowerCase().includes(this.search.toLowerCase())
				)
			},
			filteredTableData() {
				if (this.parent) return this.tableData

				return this.$_.sortBy(
					this.tableData.filter((s) => s.class == this.currentClass),
					'lastname'
				)
			},
			parent() {
				return this.$route.params.parent
			},
		},
		methods: {
			...mapActions({
				fetchEstablishmentsDB: 'establishments/fetchEstablishmentsDB',
				fetchStudentsDB: 'students/fetchStudentsDB',
				fetchClassesDB: 'classes/fetchClassesDB',
				fetchParentsDB: 'parents/fetchParentsDB',
				fetchUserDB: 'user/fetchUserDB',
			}),
			reformatDate,
			setPage(val) {
				this.page = val
			},
			indexMethod(index) {
				return index + (this.page - 1) * this.pageSize + 1
			},
			handleSelectionChange(val) {
				this.multipleSelection = val
			},
			getSexLabel(id) {
				let sex = this.sexes.find((s) => s.id == id)

				return sex ? sex.label : ''
			},
			getClassName(id) {
				let c = this.classes.find((c) => c.id == id)

				return c
					? c.name + ' ' + this.getEstablishmentLocation(c.establishment)
					: ''
			},
			getParentName(id) {
				let parent = this.parents.find((p) => p.id == id)

				return parent ? parent.name : ''
			},
			getEstablishmentLocation(id) {
				let establishment = this.establishments.find((e) => e.id == id)

				return establishment ? establishment.location : ''
			},
			handlePhotoError(err, file) {
				this.$message.error(this.$t('anErrorOccured'))
			},
			handlePhotoSuccess(res, file) {
				let url =
					STORAGE_API_BASE_URL +
					'root/' +
					this.photoData.folder +
					'/' +
					file.name

				if (this.addDialog) this.addStudent.photo = url
				else this.editStudent.photo = url
			},
			beforePhotoUpload(file) {
				const isImg =
					file.type === 'image/jpeg' ||
					'image/jpg' ||
					'image/gif' ||
					'image/png'
				const sizeLessThan3M = file.size / 1024 / 1024 < 3

				if (!isImg) this.$message.error(this.$t('uploadImageFormatWarning'))

				if (!sizeLessThan3M)
					this.$message.error(this.$t('uploadFileSizeWarning', [3]))

				return isImg && sizeLessThan3M
			},
			clearPhoto() {
				if (this.addDialog) this.addStudent.photo = null
				else this.editStudent.photo = null
			},
			async genUid(uidSrc, addSalt) {
				var uid = sanitizer.sanitize(uidSrc.toLowerCase()).substring(0, 10)

				if (addSalt) uid += this.$_.random(1, 99, false)

				var task = await this.fetchUserDB({
					action: 'checkUserExists',
					params: {
						uid: uid,
					},
				})

				if (task.success && !task.data) {
					return uid
				} else {
					return await this.genUid(uidSrc, true)
				}
			},
			async fetchStudents() {
				if (!this.adminSchoolyear) return

				this.isLoading = true

				let resp0 = await this.fetchEstablishmentsDB({
					action: 'getEstablishments',
					params: {
						schoolyear: this.adminSchoolyear,
					},
				})

				if (resp0.success && resp0.data) this.establishments = resp0.data

				let resp1 = await this.fetchClassesDB({
					action: 'getClasses',
					params: {
						schoolyear: this.adminSchoolyear,
					},
				})

				if (resp1.success && resp1.data) this.classes = resp1.data

				if (this.parent) {
					let resp0 = await this.fetchParentsDB({
						action: 'getParent',
						params: {
							id: this.parent,
						},
					})

					this.addStudent.parent = this.parent
					this.editStudent.parent = this.parent

					if (resp0.success && resp0.data) {
						this.currentParent = resp0.data

						let extracted = extractUppercaseWords(this.currentParent.name)

						if (this.$_.isArray(extracted) && extracted.length > 0) {
							this.addStudent.lastname = extracted[0]
						}
					}

					let resp = await this.fetchStudentsDB({
						action: 'getStudentsByParent',
						params: {
							schoolyear: this.adminSchoolyear,
							parent: this.parent,
						},
					})

					if (resp.success) {
						if (resp.data) this.tableData = resp.data
					} else {
						this.$message.error(this.$t('anErrorOccured'))
					}
				} else {
					let resp0 = await this.fetchParentsDB({
						action: 'getParents',
						params: {
							schoolyear: this.adminSchoolyear,
						},
					})

					if (resp0.success && resp0.data) this.parents = resp0.data

					let resp = await this.fetchStudentsDB({
						action: 'getStudents',
						params: {
							schoolyear: this.adminSchoolyear,
						},
					})

					if (resp.success) {
						if (resp.data) this.tableData = resp.data
					} else {
						this.$message.error(this.$t('anErrorOccured'))
					}
				}

				this.isLoading = false
			},
			addItem() {
				if (this.lockedData) {
					this.$message.warning(this.$t('lockedData'))

					return
				}

				if (!this.parent && this.currentClass)
					this.addStudent.class = this.currentClass

				this.addDialog = true
			},
			async editItem(index, item) {
				if (this.lockedData) {
					this.$message.warning(this.$t('lockedData'))

					return
				}

				this.currentEditItem = item

				if (item.birthday) {
					let bd = item.birthday.split('-')
					this.editStudent.birthday = new Date(
						bd[0],
						parseInt(bd[1]) - 1,
						bd[2]
					)
				} else {
					this.editStudent.birthday = null
				}

				this.editStudent.lastname = item.lastname
				this.editStudent.firstname = item.firstname
				this.editStudent.sex = parseInt(item.sex)
				this.editStudent.birthplace = item.birthplace
				this.editStudent.nationality = item.nationality
				this.editStudent.idnum = item.idnum
				this.editStudent.photo = item.photo
				this.editStudent.class = item.class
				this.editStudent.parent = item.parent == '0' ? '' : item.parent

				this.editDialog = true
			},
			deleteItem(index, item) {
				if (this.lockedData) {
					this.$message.warning(this.$t('lockedData'))

					return
				}

				this.$confirm(this.$t('deleteStudentQuestion'), this.$t('delete'), {
					confirmButtonText: this.$t('yes'),
					cancelButtonText: this.$t('cancel'),
					type: 'warning',
				})
					.then(async () => {
						let task = await this.fetchStudentsDB({
							action: 'deleteStudent',
							params: {
								id: item.id,
								user: item.user,
							},
						})

						if (task.success) {
							this.$message.success(this.$t('success'))
							this.fetchStudents()
						} else {
							this.$message.error(this.$t('anErrorOccured'))
						}
					})
					.catch(() => {})
			},
			submitForm(ref) {
				this.$refs[ref].validate(async (valid) => {
					if (valid) {
						if (ref === this.addStudentForm) {
							let name =
								this.addStudent.firstname + ' ' + this.addStudent.lastname

							let uid = await this.genUid(name, false)

							let task = await this.fetchStudentsDB({
								action: 'addStudent',
								params: {
									uid: uid,
									name: name,
									hash_id: uuidv4(),
									role: Roles.TEACHER,
									password: genPassword(),

									lastname: this.addStudent.lastname,
									firstname: this.addStudent.firstname,
									sex: this.addStudent.sex,
									birthday: this.addStudent.birthday
										? formatDate(this.addStudent.birthday, {
												outPattern: 'YYYY-MM-DD',
										  })
										: null,
									birthplace: this.addStudent.birthplace,
									nationality: this.addStudent.nationality,
									idnum: this.addStudent.idnum,
									photo: this.addStudent.photo,
									class: this.addStudent.class,
									parent: this.addStudent.parent,
									schoolyear: this.adminSchoolyear,
								},
							})

							if (task.success) this.$message.success(this.$t('success'))
							else this.$message.error(this.$t('anErrorOccured'))
						} else {
							let newData = {}

							let birthday = this.editStudent.birthday
								? formatDate(this.editStudent.birthday, {
										outPattern: 'YYYY-MM-DD',
								  })
								: null

							if (this.currentEditItem.lastname !== this.editStudent.lastname) {
								newData['lastname'] = this.editStudent.lastname
							}

							if (
								this.currentEditItem.firstname !== this.editStudent.firstname
							) {
								newData['firstname'] = this.editStudent.firstname
							}

							if (this.currentEditItem.sex !== this.editStudent.sex) {
								newData['sex'] = this.editStudent.sex
							}

							if (
								this.currentEditItem.birthplace !== this.editStudent.birthplace
							) {
								newData['birthplace'] = this.editStudent.birthplace
							}

							if (
								this.currentEditItem.nationality !==
								this.editStudent.nationality
							) {
								newData['nationality'] = this.editStudent.nationality
							}

							if (this.currentEditItem.idnum !== this.editStudent.idnum) {
								newData['idnum'] = this.editStudent.idnum
							}

							if (this.currentEditItem.photo !== this.editStudent.photo) {
								newData['photo'] = this.editStudent.photo
							}

							if (this.currentEditItem.parent !== this.editStudent.parent) {
								newData['parent'] = this.editStudent.parent || 0
							}

							if (this.currentEditItem.class !== this.editStudent.class) {
								newData['class'] = this.editStudent.class
							}

							if (this.currentEditItem.birthday !== birthday) {
								newData['birthday'] = birthday
							}

							if (!this.$_.isEmpty(newData)) {
								let task = await this.fetchStudentsDB({
									action: 'editStudent',
									params: {
										id: this.currentEditItem.id,
										data: newData,
									},
								})

								if (task.success) this.$message.success(this.$t('success'))
								else this.$message.error(this.$t('anErrorOccured'))
							}
						}

						this.cancelForm(ref)
						this.fetchStudents()
					} else {
						return false
					}
				})
			},
			cancelForm(ref) {
				this.$refs[ref].resetFields()

				if (ref === this.addStudentForm) this.addDialog = false
				else this.editDialog = false
			},
			async transfer() {
				if (this.isLoading) {
					this.$message.warning(this.$t('reportDataUnavailableMessage'))

					return
				}

				if (!this.transferClass) {
					this.$message.warning(this.$t('noClassSelected'))

					return
				}

				const students = this.$_.sortBy(this.multipleSelection, 'lastname')

				let mes = this.$message({
					message: this.$t('pleaseWait...'),
					duration: 0,
				})

				for (let i = 0; i < students.length; i++) {
					await this.fetchStudentsDB({
						action: 'editStudent',
						params: {
							id: students[i].id,
							data: {
								class: this.transferClass,
							},
						},
					})
				}

				mes.close()

				this.fetchStudents()
				this.transferDialog = false

				this.$message.success(this.$t('success'))
			},
		},
	}
</script>

<style scoped>
	.el-form-item {
		margin-bottom: 10px;
	}

	.photo-uploader .el-avatar {
		font-size: 65px;
	}

	::v-deep(.el-upload-dragger) {
		width: unset;
		height: unset;
		border-radius: 50%;
		justify-content: center;
		align-items: center;
		display: flex;
		padding: 5px;
	}

	.card-header,
	.card-footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
</style>
